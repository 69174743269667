<p-toolbar>
    <div class="p-toolbar-group-start">
        <app-apollo-button
            *ngIf="useCreate"
            [disabled]="disableCreate"
            [type]="buttonEnum.secondary"
            [tooltip]="createTooltip"
            [tooltipPosition]="createTooltipPosition"
            icon="plus"
            label="{{ createLabel }}"
            (onClick)="onCreateButtonClick()"
        ></app-apollo-button>

        <app-apollo-button
            *ngIf="useExport"
            [icon]="exportIcon"
            (onClick)="onExportExcel()"
            [tooltip]="exportTooltip"
            [tooltipPosition]="exportTooltipPosition"
            [disabled]="disableExport"
            [type]="buttonEnum.secondary"
        >
        </app-apollo-button>

        <app-filter-buttons
            [useFilter]="useFilter"
            [disableFilter]="disableFilter"
            [filterTooltip]="filterTooltip"
            [filterTooltipPosition]="filterTooltipPosition"
            (filterButtonClick)="onfilterButtonClick()"
            [useClear]="useClear"
            [disableClear]="disableClear"
            [clearTooltip]="clearTooltip"
            [clearTooltipPosition]="clearTooltipPosition"
            (clearButtonClick)="onClearButtonClick()"
        >
        </app-filter-buttons>

        <ng-container [ngTemplateOutlet]="startTemplate"></ng-container>
    </div>

    <div class="p-toolbar-group-center">
        <ng-container [ngTemplateOutlet]="centerTemplate"></ng-container>
        <app-date-selector
            *ngIf="useDate"
            [(startDate)]="startDate"
            [startDatePlaceholder]="startDatePlaceholder"
            [startDateTooltip]="startDateTooltip"
            [startMinDate]="minStartDate"
            [(endDate)]="endDate"
            [endDatePlaceholder]="endDatePlaceholder"
            [endDateTooltip]="endDateTooltip"
            (onStartDateClosed)="onStartClose($event)"
            (onEndDateClosed)="onEndClose($event)"
            [styleClass]="dateSelectorClass"
        >
        </app-date-selector>
    </div>
    <div class="p-toolbar-group-end">
        <app-search-component
            *ngIf="table && useSearch"
            [(searchText)]="globalSearch"
            [table]="table"
            (searchTextChange)="onSearchTextChange()"
        ></app-search-component>
        <ng-container [ngTemplateOutlet]="endTemplate"> </ng-container>
    </div>
</p-toolbar>
