import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { DialogService } from 'primeng/dynamicdialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Device } from '../models/device';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class FirebaseService extends ApiService {
    x: any = null;
    currentMessage = new BehaviorSubject(this.x);

    private baseUrl: string;

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        protected http: HttpClient,
        protected dialoService: DialogService,
        protected auth: AuthenticationService
    ) {
        super(http, dialoService);
        this.baseUrl = 'api/thot/devices';
    }
    requestPermission() {
        if (this.auth?.currentUser?.id) {
            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    this.postToken(token);
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                }
            );
        }
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload) => {
            this.currentMessage.next(payload);
        });
    }
    postToken(token: string): Promise<Device> {
        if (this.auth?.currentUser?.id) {
            let data = {
                registration_id: token,
                user_id: this.auth.currentUser.id,
                type: 'web',
            };
            return this.postRequest(this.baseUrl, data);
        } else {
            return null;
        }
    }
}
